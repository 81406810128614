<template>
    <PageLoader>
        <Header :title="$tc('ean', 2)" heading="h2">
            <template v-slot:actions>
                <span class="flex space-x-10">
                    <span
                        ><Button @click="openCreateEanModal = true">{{ $tc('create_ean', 1) }}</Button></span
                    >
                </span>
            </template>
        </Header>

        <section class="mt-5">
            <Table :total_count="total_ean_count" :paginationOptions="paginations" @fetchData="getSearchEans">
                <table class="min-w-full divide-y divide-fibonaki-border">
                    <thead>
                        <tr>
                            <th scope="col" class="px-4 py-3 text-left text-sm font-medium text-gray-900">{{ $tc('id', 1) }}</th>
                            <th scope="col" class="px-4 py-3 text-left text-sm font-medium text-gray-900">{{ $tc('ean', 1) }}</th>
                            <th scope="col" class="px-4 py-3 text-left text-sm font-medium text-gray-900">{{ $tc('address', 1) }}</th>
                            <th scope="col" class="px-4 py-3 text-left text-sm font-medium text-gray-900">{{ $tc('country', 1) }}</th>
                        </tr>
                    </thead>
                    <tbody v-show="Object.keys(eans).length > 0">
                        <tr v-for="ean in eans" :key="ean">
                            <td class="px-4 py-3 whitespace-nowrap text-sm text-gray-500 w-1/12">{{ ean.id }}</td>
                            <td class="px-4 py-3 whitespace-nowrap text-sm text-gray-500 w-3/12">{{ ean.ean_code }}</td>
                            <td class="px-4 py-3 whitespace-nowrap text-sm text-gray-500 w-6/12">{{ ean.address.street }} {{ ean.address.number }} {{ ean.address.addition }}, {{ ean.address.postal_code.zip }} {{ ean.address.postal_code.sub_city }}</td>
                            <td class="px-4 py-3 whitespace-nowrap text-sm text-gray-500 w-2/12">{{ ean.address.country }}</td>
                        </tr>
                    </tbody>
                </table>
            </Table>
        </section>

        <Modal :title="$tc('create_ean', 1)" :show="openCreateEanModal" @submit="createEan" @close="(openCreateEanModal = false), (this.ean = {}), (this.validationErrors = {})">
            <div class="grid grid-cols-12 gap-x-6 gap-y-3">
                <MessageBox v-if="errors.general" :title="errors.general" :messages="errors.messages" />

                <div class="col-span-12">
                    <Input :label="$tc('ean_code', 1)" type="text" id="ean_code" v-model="ean.ean_code" :error="validationErrors.ean_code" />
                </div>
                <div class="col-span-12">
                    <Input :label="$tc('street', 1)" type="text" id="street" v-model="ean.street" :error="validationErrors.street" />
                </div>
                <div class="col-span-6">
                    <Input :label="$tc('number', 1)" type="number" id="number" v-model="ean.number" :error="validationErrors.number" />
                </div>
                <div class="col-span-6">
                    <Input :label="$tc('addition', 1)" type="text" id="addition" optional v-model="ean.addition" />
                </div>
                <div class="col-span-12">
                    <SelectWithSearch :label="$tc('city', 1)" id="postal_code_id" v-model="ean.postal_code_id" :options="cityOptions" displayProperty="display_name" valueProperty="id" :minLengthForDropdown="3" :error="validationErrors.postal_code_id" />
                </div>
                <div class="col-span-12">
                    <Input :label="$tc('country', 1)" type="text" id="country" v-model="ean.country" :error="validationErrors.country" />
                </div>
            </div>
        </Modal>
    </PageLoader>
</template>

<script>
    import { validateEan } from '@/utils/validators';
    import AddressService from '@/services/AddressService';
    import MessageBox from '../../components/general/MessageBox.vue';
    import ClientService from '@/services/ClientService';

    export default {
        async created() {
            this.getSearchEans('', 0, this.paginations[0]);
            this.getCities();
        },
        data() {
            return {
                cityOptions: [],
                ean: {
                    ean_code: null,
                    country: 'Belgium',
                    postal_code_id: null,
                    street: null,
                    number: null,
                    addition: '',
                },
                openCreateEanModal: false,
                errors: {},
                validationErrors: {},
                total_ean_count: 0,
                paginations: [5, 10, 20, 50],
                eans: {},
            };
        },
        methods: {
            async getCities() {
                const response = await AddressService.getCities();
                this.cityOptions = response.data.cities.map((city) => {
                    return {
                        id: city.id,
                        display_name: `${city.sub_city} (${city.zip})`,
                    };
                });
            },
            async createEan() {
                try {
                    this.validationErrors = {};
                    if (!this.ean.ean_code) this.validationErrors.ean_code = this.$tc('fill_in_the_ean_code', 1);
                    else if (!validateEan(this.ean.ean_code)) this.validationErrors.ean_code = this.$tc('invalid_ean_code', 1);

                    if (!this.ean.street) this.validationErrors.street = this.$tc('fill_in_the_street', 1);
                    if (!this.ean.number) this.validationErrors.number = this.$tc('fill_in_the_number', 1);
                    if (!this.ean.postal_code_id) this.validationErrors.postal_code_id = this.$tc('fill_in_the_city', 1);
                    if (!this.ean.country) this.validationErrors.country = this.$tc('fill_in_the_country', 1);

                    if (Object.keys(this.validationErrors).length === 0) {
                        await this.$store.dispatch('clients/createEan', { client_id: this.$route.params.client_id, ean: this.ean });
                        console.log(this.ean);
                        this.openCreateEanModal = false;
                        this.ean = {};
                    }
                } catch (error) {
                    console.log(error);
                    // if (error.response.data.message) {
                    //     this.errors = {
                    //         general: error.response.data.message,
                    //     };
                    // } else {
                    //     this.errors = {
                    //         general: `${this.$tc('unknown_error', 1)}<br /> ${this.$tc('please_try_agin', 1)}`,
                    //     };
                    // }
                }
            },
            async getSearchEans(search, offset, amount) {
                const eanResponse = await ClientService.getEans(this.$route.params.client_id, search, offset, amount);
                this.eans = eanResponse.data.eans;
                this.total_ean_count = eanResponse.data.total_count;
            },
        },
        components: { MessageBox },
    };
</script>
